const globals = {
    parameters : {debugging : false, read_forms_only:false},
    dico:{
        fr:{
            interface:{
                main_title:"rBase",
                browse_page_sub_title:"Liste des formulaire disponibles",
                input_data_page_sub_title:"Entrez les données du formulaire",
                label_formulaire:"Formulaire: ",
                label_nombre_lignes_formulaire:"Nombre de lignes: "
            },
            message:{
                donnees_formulaire_enregistrees : "Données du formulaire enregistrées",
                formulaire_cree : "Formulaire créé",
                formulaire_charge : "Formulaire chargé",
                verifier_connexion_wifi : "Verifiez votre connexion wifi",
                nouveau_record_ajoute : "Nouveau record ajouté",
                veuillez_saisir_les_champs : "Veuillez saisir les champs !"
            },
            button:{
                nouveau_formulaire : "Nouveau formulaire",
                exporter : "Exporter...",
                partager : "Partager...",
                ajouter_un_champ : "Ajouter un champ ...",
                ajouter_un_enregistrement : "Ajouter",
                tout_enregistrer : "Tout enregistrer.",
                effacer_un_champ : "Effacer...",
                effacer_cet_enregistrement : " Effacer",
                retour_a_la_liste : "Retour à la liste"
            }
        },
        gb:{

        }
    }
}

export {globals}